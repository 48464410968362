export enum LOOPRING_URLs {
  GET_AVAILABLE_BROKER = "/api/v3/getAvailableBroker",
  GET_RELAYER_CURRENT_TIME = "/api/v3/timestamp",
  API_KEY_ACTION = "/api/v3/apiKey", // get update
  GET_NEXT_STORAGE_ID = "/api/v3/storageId",
  ORDER_ACTION = "/api/v3/order", // get submit cancel
  ORDER_CANCEL_HASH_LIST = "/api/v2/orders/byHash", // cancel multiple orders by hashs
  ORDER_CANCEL_CLIENT_ORDER_ID_LIST = "/api/v2/orders/byClientOrderId", // cancel multiple orders by clientOrderids
  GET_MULTI_ORDERS = "/api/v3/orders",
  GET_MARKETS = "/api/v3/exchange/markets",
  GET_TOKENS = "/api/v3/exchange/tokens",
  GET_EXCHANGE_INFO = "/api/v3/exchange/info",
  GET_WITHDRAWAL_AGENTS = "/api/v3/exchange/withdrawalAgents",
  GET_EXCHANGE_FEEINFO = "/api/v3/exchange/feeInfo",
  GET_IGNORE_WITHDRAW = "/api/v3/exchange/notWithdrawContractTokens",

  GET_MIX_MARKETS = "/api/v3/mix/markets",
  GET_DEPTH = "/api/v3/depth",
  GET_MIX_DEPTH = "/api/v3/mix/depth",
  GET_TICKER = "/api/v3/ticker",
  GET_MIX_TICKER = "/api/v3/mix/ticker",
  GET_CANDLESTICK = "/api/v3/candlestick",
  GET_MIX_CANDLESTICK = "/api/v3/mix/candlestick",
  GET_FIAT_PRICE = "/api/v3/price",
  GET_TRADES = "/api/v3/trade",
  POST_INTERNAL_TRANSFER = "/api/v3/transfer",
  ACCOUNT_ACTION = "/api/v3/account", // get or update
  COUNTER_FACTUAL_INFO = "/api/v3/counterFactualInfo",
  GET_USER_REG_TXS = "/api/v3/user/createInfo",
  GET_PWD_RESET_TXS = "/api/v3/user/updateInfo",
  GET_USER_EXCHANGE_BALANCES = "/api/v3/user/balances",
  GET_USER_DEPOSITS_HISTORY = "/api/v3/user/deposits",
  WITHDRAWALS_ACTION = "/api/v3/user/withdrawals", // post get
  POST_FORCE_WITHDRAWALS = "/api/v3/user/forceWithdrawals",

  GET_USER_TRANSFERS_LIST = "/api/v3/user/transfers",
  GET_USER_TRADE_HISTORY = "/api/v3/user/trades",
  GET_USER_TXS = "/api/v3/user/transactions",
  GET_USER_FEE_RATE = "/api/v3/user/feeRates", // deprecated
  GET_USER_ORDER_FEE_RATE = "/api/v3/user/orderFee",
  GET_MINIMAL_ORDER_AMT = "/api/v3/user/orderAmount", // IGNORE for now.
  GET_MINIMUM_TOKEN_AMT = "/api/v3/user/orderUserRateAmount",
  GET_OFFCHAIN_FEE_AMT = "/api/v3/user/offchainFee",

  GET_ALLOWANCES = "/api/v3/eth/allowances",
  GET_ETH_NONCE = "/api/v3/eth/nonce",
  GET_ETH_BALANCES = "/api/v3/eth/balances",
  GET_TOKEN_BALANCES = "/api/v3/eth/tokenBalances",
  GET_GAS_PRICE = "/api/v3/eth/recommendedGasPrice",
  GET_GAS_PRICE_RANGE = "/api/v3/eth/recommendedGasPriceRange",

  GET_RECOMENDED_MARKETS = "/api/v3/exchange/recommended",

  GET_AMM_POOLS_CONF = "/api/v3/amm/pools",
  GET_AMM_POOLS_SNAPSHOT = "/api/v3/amm/balance",
  GET_AMM_POOLS_BALANCES = "/api/v3/amm/balances",
  GET_AMM_POOL_STATS = "/api/v2/amm/poolsStats",
  POST_JOIN_AMM_POOL = "/api/v3/amm/join",
  POST_EXIT_AMM_POOL = "/api/v3/amm/exit",
  GET_AMM_POOL_TXS = "/api/v3/amm/transactions",
  GET_USER_AMM_POOL_TXS = "/api/v3/amm/user/transactions",
  GET_AMM_POOL_TRADE_TXS = "/api/v3/amm/trades",
  GET_AMM_ACTIVITY_RULES = "/api/v3/sidecar/activityRules",
  GET_AMMPOOL_USER_REWARDS = "/api/v3/amm/user/rewards",
  GET_AMMPOOL_REWARDS = "/api/v3/amm/rewards",
  GET_AMMPOOL_GAME_RANK = "/api/v3/game/rank",
  GET_AMMPOOL_GAME_USER_RANK = "/api/v2/game/user/rank",
  GET_LIQUIDITY_MINING = "/api/v2/sidecar/liquidityMining",
  GET_DELEGATE_GET_CODE = "/api/v3/delegator/getCode",
  GET_DELEGATE_GET_IPFS = "/api/v3/delegator/ipfs",
  GET_LIQUIDITY_MINING_USER_HISTORY = "/api/v2/sidecar/liquidityMiningUserHistory",
  GET_PROTOCOL_PORTRAIT = "/api/v3/sidecar/ProtocolPortrait",
  GET_AMM_ASSET_HISTORY = "/api/v3/amm/assets",
  GET_ASSET_LOCK_RECORDS = "api/v3/user/lockRecords",

  GET_DEFI_TOKENS = "/api/v3/defi/tokens",
  GET_DEFI_MARKETS = "/api/v3/defi/markets",
  POST_DEFI_ORDER = "/api/v3/defi/order",
  GET_DEFI_REWARDS = "/api/v3/defi/rewards",
  GET_DEFI_TRANSACTIONS = "/api/v3/defi/transactions",

  SET_REFERRER = "/api/v3/refer",

  GET_WS_KEY = "/v3/ws/key",

  GET_USER_ASSETS = "/api/wallet/v3/userAssets",
  GET_USER_TRADE_AMOUNT = "/api/v3/datacenter/getUserTradeAmount",
  GET_TOKEN_PRICES = "/api/wallet/v3/tokenPrices",
  GET_LATEST_TOKEN_PRICES = "/api/v3/datacenter/getLatestTokenPrices",
  GET_GUARDIAN_APPROVE_LIST = "/api/wallet/v3/getGuardianApproveList",
  GET_PROTECTORS = "/api/wallet/v3/getProtects",
  GET_OPERATION_LOGS = "/api/wallet/v3/operationLogs",
  GET_HEBAO_CONFIG = "/api/wallet/v3/getAppConfigs",
  GET_WALLET_TYPE = "/api/wallet/v3/wallet/type",
  GET_WALLET_MODULES = "/api/wallet/v3/walletModules",
  GET_WALLET_CONTRACTVERSION = "/api/wallet/v3/contractVersion",

  SUBMIT_APPROVE_SIGNATURE = "/api/wallet/v3/submitApproveSignature",
  REJECT_APPROVE_SIGNATURE = "/api/wallet/v3/rejectApproveSignature",
  OFFICIAL_LOCK_OR_UNLOCK = "/api/wallet/v3/officialLockOrUnlock",
  RESOLVE_ENS = "/api/wallet/v3/resolveEns",
  RESOLVE_NAME = "/api/wallet/v3/resolveName",
  SEND_META_TX = "/api/wallet/v3/sendMetaTx",

  GET_ACCOUNT_SERVICES = "/api/v3/spi/getAccountServices",
  // VIP
  GET_USER_VIP_INFO = "/api/v3/user/vipInfo",
  GET_USER_VIP_ASSETS = "/api/v3/datacenter/getUserAssets",
  GET_USER_NFT_BALANCES = "/api/v3/user/nft/balances",
  GET_USER_NFT_BALANCES_BY_COLLECTION = "/api/v3/user/nft/collection/balances",
  GET_NFT_OFFCHAIN_FEE_AMT = "/api/v3/user/nft/offchainFee",
  POST_NFT_INTERNAL_TRANSFER = "/api/v3/nft/transfer",
  POST_NFT_WITHDRAWALS = "/api/v3/nft/withdrawal",
  POST_NFT_MINT = "/api/v3/nft/mint",
  POST_NFT_TRADE = "/api/v3/nft/trade",
  POST_NFT_VALIDATE_ORDER = "/api/v3/nft/validateOrder", // post get
  POST_NFT_CREATE_COLLECTION = "/api/v3/nft/collection",
  POST_NFT_EDIT_COLLECTION = "/api/v3/nft/collection/edit",
  POST_NFT_VALIDATE_REFRESH_NFT = "/api/v3/nft/image/refresh",
  POST_DEPLOY_COLLECTION = "/api/v3/collection/deployTokenAddress",
  GET_NFT_COLLECTION = "/api/v3/nft/collection",
  GET_NFT_COLLECTION_HASNFT = "/api/v3/user/collection/details",
  GET_NFT_COLLECTION_PUBLISH = "api/v3/nft/public/collection",

  // POST_NFT_COLLECTION = "/api/v3/collection/createCollection",
  // GET_NFT_COLLECTIONS = "/api/v3/nft/collections",
  // GET_NFTs_BY_COLLECTIONS = "/api/v3/nft/collection/items",
  // GET_USER_NFT_COLLECTIONS = "/api/v3/nft/collections",
  // GET_USER_NFTs_BY_COLLECTIONS = "/api/v3/collection/nftBalances",
  GET_NFTs_INFO = "/api/v3/nft/info/nfts",
  GET_USER_NFT_TRANSFER_HISTORY = "/api/v3/user/nft/transfers",
  GET_USER_NFT_DEPOSIT_HISTORY = "/api/v3/user/nft/deposits",
  GET_USER_NFT_WITHDRAW_HISTORY = "/api/v3/user/nft/withdrawals",
  GET_USER_NFT_TRANSACTION_HISTORY = "/api/v3/user/nft/transactions",
  GET_USER_NFT_TRADE_HISTORY_OLD = "/api/v3/user/nft/trades",
  GET_USER_NFT_TRADE_HISTORY = "/api/v3/new/user/nft/trades",
  GET_USER_NFT_MINT_HISTORY = "/api/v3/user/nft/mints",
  GET_DEPLOY_TOKEN_ADDRESS = "/api/v3/nft/deployTokenAddress",
  IPFS_META_URL = "https://infura-ipfs.io/ipfs/",

  GET_DUAL_INDEX = "/api/v3/dual/index",
  GET_DUAL_PRICES = "/api/v3/dual/prices",
  GET_DUAL_INFOS = "/api/v3/dual/infos",
  GET_DUAL_TRANSACTIONS = "/api/v3/dual/transactions",
  GET_DUAL_BALANCE = "/api/v3/dual/balance",
  GET_DUAL_RULE = "/api/v3/dual/rules",
  POST_DUAL_ORDER = "/api/v3/dual/order",
  GET_DUAL_USER_LOCKED = "/api/v3/dual/lockRecordAmount",
}
